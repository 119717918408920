import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Image, Button } from 'antd';
import { getTranslation, getSelectedTranslation } from '../../core/utils/helpers';
import useLanguage from '../../core/language/useLanguage';
import './displayMoonsign.scss';
import { resetMoonsignLookup } from '../../core/horoscope/horoscopeActions';

const DisplayMoonsign = () => {
    const { language } = useLanguage();
    const dispatch = useDispatch();

    const { searchedMoonsign } = useSelector((state) => state.horoscope);

    const translation = getSelectedTranslation(searchedMoonsign?.translations, language?.selectedLanguage?.id);

    const handleSearchAgainClick = () => {
        dispatch(resetMoonsignLookup());
    };

    return (
        <div className="display-moonsign-container">
            <h1 className="title-text">{getTranslation(language, 'moonsign_generate_text_title', 'We have generated your Moonsign')}  </h1>
            <Image preview={false} height={354} width={337} src={`${searchedMoonsign?.mediaUrl}`} />
            <p className="sub-title-text" style={{ fontFamily: 'Inter' }}> {translation?.name} </p>

            <Button type="primary" style={{ marginBottom: 10 }} className="ant-btn-yellow" onClick={handleSearchAgainClick}>
                {getTranslation(language, 'search_another_moonsign_button_title', 'Search Another Moonsign')}
            </Button>

        </div>
    );
};

export default DisplayMoonsign;

// import { useDispatch } from 'react-redux';
/* eslint-disable */
import React from 'react';
import { Button, Form, Input, Row, Col, message } from 'antd';
import { useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import './addPaymentCardForm.scss';
import usePayment from '../../../../core/payment/usePayment';
import { getTranslation, getSelectedPaymentPlan } from '../../../../core/utils/helpers';
import useLanguage from '../../../../core/language/useLanguage';

const FormItem = Form.Item;

const CheckoutForm = ({ type, consultation_id, questions, extra_questions, phone_number }) => {

    // const form = Form.useForm();
    const { addPaymentCardRequest, payment } = usePayment();
    const { language } = useLanguage();
    const horoscope = useSelector(state => state.horoscope);
    const user = useSelector(state => state.user.userData);

    const getPaymentProperties = (paymentType) => {
        if (paymentType === 'oneOffPurchase' || paymentType === 'oneOffPurchaseMobile') {
            return {
                payment_plan_id: getSelectedPaymentPlan(payment?.selectedOneOffPaymentPlan)?.payment_plan_id,
                horoscope_id: payment.selectedOneOffPaymentPlan?.id,
                horoscope_data_id: payment.selectedOneOffPaymentPlan?.horoscopeData?.id,
            };
        }
        if (paymentType === 'consultation') {

            return {
                consultation_id,
                questions,
                extra_questions,
                phone_number,
            };
        }
        if (paymentType === 'lifeEvent') {
            return {
                horoscopeDataParts: payment?.lifeEvents,
                selectedLang: language?.selectedLanguage?.id,
            };
        }
        if (paymentType === 'horoscope') {
            return {
                payment_plan_id: payment?.selectedPlan?.id,
                horoscope_id: horoscope?.singleHoroscope?.id,
            };
        }
        return {};
    };

    const handleSubmit = (values) => {
        let validate = true;

        const exp_month = values.card_exp_month;
        const exp_year = values.card_exp_year;

        if (!exp_month || !exp_year) {
            validate = false;
            message.error('Please enter valid Expiry date');
        }

        if (validate) {
            const commonProperties = {
                card_number: values.card_number,
                card_exp_month: exp_month,
                card_exp_year: exp_year,
                card_cvc: values.card_cvc,
                holders_name: values.holders_name,
                user_id: user?.id,
                type,
                selectedLang: language?.selectedLanguage?.id,
            };

            const paymentProperties = getPaymentProperties(type);

            // Log the data being sent
            console.log('Submitting payment data:', { ...commonProperties, ...paymentProperties });

            // addPaymentCardRequest({ ...commonProperties, ...paymentProperties });
        }
    };

    return (
        <Form onFinish={handleSubmit} layout="vertical" className="form payment-form">
            <div>
                <div className="payment-form-content-wrapper">
                    <FormItem
                        name="card_number"
                        label={getTranslation(language, 'payment_card_form_card_number_label', 'Card Number:')}
                        rules={[{ max: 16, message: getTranslation(language, 'payment_card_form_card_number_valid_max', 'Field cannot be longer than 16') }, { required: true, message: getTranslation(language, 'payment_card_form_card_number_valid_required', 'Please enter Card Number!') }]}>
                        <Input maxLength={16} className="payment-input" placeholder="0000 0000 0000 0000" />
                    </FormItem>
                    <FormItem
                        name="holders_name"
                        label={getTranslation(language, 'payment_card_form_holders_name_label', "Cardholder's Name:")}
                        rules={[{ required: true, message: getTranslation(language, 'payment_card_form_holders_name_valid_required', "Please enter Cardholder's Name!") }]}>
                        <Input size="large" className="payment-input" placeholder="ex. Jonathan Paul Ive" />
                    </FormItem>

                    <Row gutter={0} align="middle">
                        <Col span={11}>
                            <FormItem
                                name="card_exp_month"
                                label={getTranslation(language, 'payment_card_form_card_exp_month_label', 'Expiry Month')}
                                rules={[
                                    { required: true, message: getTranslation(language, 'payment_card_form_card_exp_month_valid_required', 'Please enter Expiry Month') },
                                    { max: 2, message: getTranslation(language, 'payment_card_form_card_exp_month_valid_format', 'Enter a valid month (MM)') },
                                    { min: 2, message: getTranslation(language, 'payment_card_form_card_exp_month_valid_format', 'Enter a valid month (MM)') },
                                ]}>
                                <Input size="large" maxLength={2} className="payment-input" placeholder="MM" />
                            </FormItem>
                        </Col>
                        <Col span={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <span className="expiry-slash">/</span>
                        </Col>
                        <Col span={11}>
                            <FormItem
                                name="card_exp_year"
                                label={getTranslation(language, 'payment_card_form_card_exp_year_label', 'Expiry Year')}
                                rules={[
                                    { required: true, message: getTranslation(language, 'payment_card_form_card_exp_year_valid_required', 'Please enter Expiry Year') },
                                    { max: 4, message: getTranslation(language, 'payment_card_form_card_exp_year_valid_format', 'Enter a valid year (YYYY)') },
                                    { min: 4, message: getTranslation(language, 'payment_card_form_card_exp_year_valid_format', 'Enter a valid year (YYYY)') },
                                ]}>
                                <Input size="large" maxLength={4} className="payment-input" placeholder="YYYY" />
                            </FormItem>
                        </Col>
                    </Row>
                    <FormItem
                        name="card_cvc"
                        label={getTranslation(language, 'payment_card_form_card_cvc_label', 'Security Code:')}
                        rules={[{ required: true, message: getTranslation(language, 'payment_card_form_card_cvc_valid_required', 'Please enter Security Code (CSV)!') }]}>
                        <Input size="large" className="payment-input" placeholder="3-4 digits" />
                    </FormItem>
                </div>
                <div className="text-center mt-5">
                    {payment.isFetching ? (
                        <Button type="primary" htmlType="submit" className="ant-btn-yellow" loading>
                            {getTranslation(language, 'payment_card_form_pay_button_label', 'Pay')}
                        </Button>
                    ) : (
                        <Button type="primary" htmlType="submit" className="ant-btn-yellow">
                            {getTranslation(language, 'payment_card_form_pay_button_label', 'Pay')}
                        </Button>
                    )}
                </div>
            </div>
        </Form>
    );
};

CheckoutForm.propTypes = {
    type: PropTypes.string,
    consultation_id: PropTypes.string,
    questions: PropTypes.array,
    extra_questions: PropTypes.string,
    phone_number: PropTypes.string,
};

CheckoutForm.defaultProps = {
    type: '',
    consultation_id: '',
    questions: [],
    extra_questions: '',
    phone_number: '',
};

export default CheckoutForm;

import React from 'react';
import { Button } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { getSelectedTranslation, getTranslation } from '../../core/utils/helpers';

import history from '../../core/utils/history';
import Main from '../../components/layout/Main';
import usePayment from '../../core/payment/usePayment';
import useHoroscope from '../../core/horoscope/useHoroscope';
import useLanguage from '../../core/language/useLanguage';
import tickTicked from '../../assets/images/tick-ticked.png';
import { buySubscriptionRequest } from '../../core/payment/paymentActions';

import './orderConfirm.scss';

const OrderConfirm = () => {

    const toolbar = [{ label: 'Item 1', link: '/abc', iconName: 'home' }];
    const menu = [{ label: 'Item 1', link: '/abc', iconName: 'home', style: { fontSize: 24, color: 'red' } }];

    const dispatch = useDispatch();
    const location = useLocation();
    const { language } = useLanguage();
    const { payment } = usePayment();
    const { horoscope } = useHoroscope();

    const paymentTranslation = getSelectedTranslation(payment?.selectedPlan?.translations, language?.selectedLanguage?.id);
    const horoscopeTranslation = getSelectedTranslation(horoscope?.singleHoroscope?.translations, language?.selectedLanguage?.id);

    const termsDefaultText = 'Payment will be charged to your debit or credit card when you press PAY on the following '
        + 'Card Details Payment screen. Your subscription will start immediately and automatically renew '
        + '(at the duration and price selected) unless you cancel your subscription before the next '
        + 'subscription period begins. Renewal charges will be applied to your payment card on the '
        + 'renewal date of your subscription. The minimum subscription period is one week, one month, '
        + 'and one year for the Silver, Gold, and Diamond Subscriptions respectively, after which you '
        + 'can cancel your subscription at any time. Any current free trial will be forfeited upon '
        + 'subscription purchase.';

    const makePayment = () => {
        const error = false;
        if (!error) {
            dispatch(buySubscriptionRequest({
                payment_plan_id: payment?.selectedPlan?.id,
                card_id: 0,
                horoscope_id: horoscope?.singleHoroscope?.id,
                coupon_code_id: payment?.couponCode?.data?.coupon?.id,
                selectedLang: language?.selectedLanguage?.id,
            }));
        }
    };

    const renderPaymentButtons = () => {
        if (payment.isFetching) {
            return (
                <Button type="primary" className="ant-btn-yellow" loading>
                    {getTranslation(language, 'payment_card_promocode_button_label', 'Confirm Promo Code')}
                </Button>
            );
        }
        return (
            <Button type="primary" className="ant-btn-yellow" onClick={() => makePayment()}>
                {getTranslation(language, 'payment_card_promocode_button_label', 'Confirm Promo Code')}
            </Button>
        );

    };

    return (
        <Main
            className="order-confirm-page center-align"
            title={<div>Welcome</div>}
            headerTitle={<div>Horoscope Generated</div>}
            menu={menu}
            menuWidth={350}
            menuPlacement="right"
            toolbar={toolbar}
            footerContent={<div style={{ lineHeight: '30px', fontSize: '16px', float: 'right' }}>&copy; copyright 2019</div>}
            showHeader={false}
            showFooter={false}
            backLink={(
                <Button onClick={() => console.log('Back')}>
                    <LeftOutlined /> {getTranslation(language, 'navigation_title_back', 'Back')}
                </Button>
            )}>
            <div>
                <h1 className="title-text">{getTranslation(language, 'subscription_confirm_screen_title', 'Order confirmation')}</h1>
                <div className="order-confirm-details-block">
                    <h2 className="title-text">{horoscopeTranslation?.name}</h2>
                    <p>{getTranslation(language, 'subscription_confirm_screen_price_label', 'Price:')}</p>

                    {location?.appliedCouponCodeData
                        ? <p><strike>£{payment?.selectedPlan?.amount} {paymentTranslation?.frequency_title}</strike> £0 {paymentTranslation?.frequency_title}  </p>
                        : <p>£{payment?.selectedPlan?.amount} {paymentTranslation?.frequency_title}</p>}

                    {location?.appliedCouponCodeData
                        ? (
                            <p className="description">
                                {getTranslation(language, 'period_text', 'Period')} - {payment?.couponCode?.data?.coupon?.period} {getTranslation(language, 'week_text', 'week')}
                            </p>
                        )
                        : null }

                    <p className="description">
                        <img className="horoscope-selected-icon" src={tickTicked} alt="Tickticked" />
                        {paymentTranslation?.title} {getTranslation(language, 'subscription_confirm_screen_subscription_text', 'Subscription')} - {paymentTranslation?.period_title} {getTranslation(language, 'subscription_confirm_screen_horoscopes_text', 'Horoscopes')}
                    </p>

                    {location?.appliedCouponCodeData
                        ? renderPaymentButtons()
                        : (
                            <Button onClick={() => history.replace({ pathname: '/enter-payment-details', state: { horoscope_id: horoscope?.id, type: 'horoscope' } })} type="primary" className="ant-btn-yellow">
                                {getTranslation(language, 'subscription_confirm_screen_submit_button_text', 'Continue')}
                            </Button>
                        )}

                    <br />
                    <p className="terms-text">{getTranslation(language, 'webapp_subscriptions_terms_text', termsDefaultText)}</p>

                    <div className="custom-footer">
                        {getTranslation(language, 'terms_and_conditions_start_text', 'Please see also our')}{' '}
                        <a href="https://www.prakashastrologer.com/terms-of-use/" className="backlink" target="_blank" rel="noreferrer">
                            {getTranslation(language, 'terms_and_conditions_link_text', 'Terms and Conditions')}
                        </a>
                        {' '}{getTranslation(language, 'form_agree_terms_label_part_2', 'and')}{' '}
                        <a href="https://www.prakashastrologer.com/privacy-policy/" className="backlink" target="_blank" rel="noreferrer">
                            {getTranslation(language, 'privacy_policy_link_text', 'Privacy Policy')}
                        </a>
                    </div>

                    <Link to="/payment-plan-select" className="text-small back-link"> {getTranslation(language, 'webapp_back_link_title', 'Back')} </Link>
                </div>
            </div>
        </Main>
    );
};

export default OrderConfirm;

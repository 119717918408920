import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, DatePicker, message } from 'antd';
import LocationSearchInput from '../LocationSearchInput';
import '../../../pages/Horoscope/horoscope.scss';
import './moonsignLookup.scss';
import useUser from '../../../core/user/useUser';
import { getTranslation } from '../../../core/utils/helpers';
import useLanguage from '../../../core/language/useLanguage';
import { moonsignLookupRequest } from '../../../core/horoscope/horoscopeActions';

const MoonsignLookupForm = () => {
    const { language } = useLanguage();

    const dispatch = useDispatch();

    const [dob, setDob] = useState('');
    const [placeOfBirth, setPlaceOfBirth] = useState('');

    const { user } = useUser();

    const handleSubmit = () => {

        let error = false;

        if (dob === '') {
            error = true;
            message.error(getTranslation(language, 'sign_up_second_screen_date_of_birth_validation', 'Please select Date of birth'));
        }

        if (placeOfBirth === '') {
            error = true;
            message.error(getTranslation(language, 'sign_up_second_screen_place_of_birth_validation', 'Please select Place of birth'));
        }

        const data = {
            dob,
            placeOfBirth,
        };

        if (!error) {
            dispatch(moonsignLookupRequest({
                data,
            }));
        }
    };

    return (
        <div>
            <div className="moonsign-lookup-form-wrap">
                <div className="input-wrapper">
                    <p className="signupform-p">{getTranslation(language, 'form_date_and_time_of_birth_label', 'Date and time of birth')}</p>
                    <DatePicker showTime
                        style={{ background: '#ff000000' }}
                        format="YYYY-MM-DD HH:mm:00"
                        placeholder={getTranslation(language, 'form_date_and_time_of_birth_label', 'Date and time of birth')}
                        onChange={(value) => { setDob(value); }} />
                </div>
                <div className="input-wrapper">
                    <p className="signupform-p">{getTranslation(language, 'form_place_of_birth_label', 'Place of birth')}</p>
                    <LocationSearchInput setLocation={(data) => { setPlaceOfBirth(data); }} />
                </div>
                <div className="button-wrapper">
                    <Button type="primary" htmlType="submit" className="ant-btn-yellow" loading={user.isFetching} onClick={handleSubmit}>
                        {getTranslation(language, 'lookup_moonsign', 'Lookup Your Moonsign')}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default MoonsignLookupForm;

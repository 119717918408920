// import { useDispatch } from 'react-redux';
import { React, useState, useEffect } from 'react';
import { Button, Row, Col, Image } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'underscore';
import { LeftOutlined } from '@ant-design/icons';
import Main from '../../components/layout/Main';
import './horoscope.scss';
import useHoroscope from '../../core/horoscope/useHoroscope';
import history from '../../core/utils/history';
import { findUserHoroscopeRequest, horoscopeListRequest } from '../../core/horoscope/horoscopeActions';
import useLanguage from '../../core/language/useLanguage';
import { getTranslation, getSelectedTranslation } from '../../core/utils/helpers';

const MoonsignSelect = () => {
    const dispatch = useDispatch();
    const { language } = useLanguage();
    const { horoscope, selectSingleHoroscopeRequest } = useHoroscope();

    const toolbar = [{ label: 'Item 1', link: '/abc', iconName: 'home' }];
    const menu = [{ label: 'Item 1', link: '/abc', iconName: 'home', style: { fontSize: 24, color: 'red' } }];

    const [userSelectedHoroscope, setUserSelectedHoroscope] = useState(null);
    const [countdown, setCountdown] = useState(5);

    const { isLoggedIn } = useSelector(state => state.user);

    // whenever selectedHoroscope state is updated change userSelectedHoroscope
    useEffect(() => {
        const { singleHoroscope } = horoscope;
        setUserSelectedHoroscope(singleHoroscope);
    }, [horoscope?.singleHoroscope]);

    useEffect(() => {
        dispatch(horoscopeListRequest());
    }, []);

    useEffect(() => {
        if (!isLoggedIn) {
            const timer = setInterval(() => {
                setCountdown(prev => {
                    if (prev === 1) {
                        clearInterval(timer);
                        history.push('/?redirect=moonsign-select');
                    }
                    return prev - 1;
                });
            }, 1000);

            return () => clearInterval(timer);
        }
        return undefined;
    }, [isLoggedIn]);

    const handleSubmit = () => {
        history.push('/payment-plan-select');
    };

    // handle click update the redux state
    const handleMoonsignSelect = (value) => {
        setUserSelectedHoroscope(value);
        selectSingleHoroscopeRequest(value);
        findUserHoroscopeRequest();
    };

    const moonSigns = () => {
        const arr = [];
        if (!isEmpty(horoscope?.horoscopes)) {
            horoscope.horoscopes.forEach(e => {
                const selectedTranslation = getSelectedTranslation(e?.translations, language?.selectedLanguage?.id);
                const sltCls = +userSelectedHoroscope?.id === +e.id ? 'selected-moonsign' : '';
                arr.push(
                    <Col onClick={() => handleMoonsignSelect(e)} id={e.id} xs={6} sm={6} md={6} lg={8} xl={4}>
                        <div className={`${sltCls} gutter-row cursor-pointer moonsign-images`}>
                            <Image style={{ alignSelf: 'center', width: 90, margin: 2 }} preview={false} src={`${e.iconUrl}`} />
                            <p style={{ position: 'absolute', color: 'white', fontSize: '20px', bottom: -50 }}>
                                {selectedTranslation?.name}
                            </p>
                        </div>
                    </Col>,
                );
            });
        }
        return arr;
    };

    return (
        <Main
            className="moonsignSelect center-align"
            title={<div>Welcome</div>}
            headerTitle={<div>{getTranslation(language, 'horoscope_generated_screen_title', 'Horoscope Generated')}</div>}
            menu={menu}
            menuWidth={350}
            menuPlacement="right"
            toolbar={toolbar}
            footerContent={<div style={{ lineHeight: '30px', fontSize: '16px', float: 'right' }}>&copy; copyright 2019</div>}
            showHeader={false}
            showFooter={false}
            backLink={(
                <Button onClick={() => console.log('Back')}>
                    <LeftOutlined /> {getTranslation(language, 'navigation_button_back_title', 'Back')}
                </Button>
            )}>
            {isLoggedIn ? (
                <>
                    <h1 className="title-text mb-16" style={{ fontSize: '20px' }}> {getTranslation(language, 'select_moonsign_header_title', 'Select your Moonsign')} </h1>
                    <Row gutter={[0, 90]} style={{ margin: '5%' }}>
                        {moonSigns()}
                    </Row>

                    <Button onClick={handleSubmit} className="ant-btn-yellow" style={{ marginTop: '20vh' }} htmlType="submit" type="primary">
                        {getTranslation(language, 'choose_moonsign_option_title', 'Choose your Moonsign')}
                    </Button>
                </>
            ) : (
                <div className="centered-message">
                    <p>Create an account or login to continue</p>
                    <p>Redirecting in {countdown} seconds...</p>
                    <Button onClick={() => history.push('/')} type="primary">
                        Go to Login
                    </Button>
                </div>
            )}
        </Main>
    );
};

export default MoonsignSelect;

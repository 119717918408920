import { call, put, takeEvery } from 'redux-saga/effects';
import { message } from 'antd';
import { fetchApi, fetchApiAuth } from '../utils/api';

import {
    horoscopeListSuccess,
    horoscopeListFailure,

    findUserHoroscopeSuccess,
    findUserHoroscopeFailure,

    userActiveHoroscopesSuccess,
    userActiveHoroscopesFailure,

    checkIfLiveSuccess,
    checkIfLiveFailure,

    userPreviousHoroscopesSuccess,
    userPreviousHoroscopesFailure,

    getNewSingleHoroscopeSuccess,
    getNewSingleHoroscopeFailure,

    moonsignLookupSuccess,
    moonsignLookupFailure,

} from './horoscopeActions';

const {
    HOROSCOPE_LIST_REQUEST,
    FIND_USER_HOROSCOPE_REQUEST,
    USER_ACTIVE_HOROSCOPES_REQUEST,
    CHECK_IF_LIVE_REQUEST,
    USER_PREVIOUS_HOROSCOPES_REQUEST,
    GET_NEW_SINGLE_HOROSCOPE_REQUEST,
    MOONSIGN_LOOKUP_REQUEST,
} = require('./horoscopeActions').constants;

function* horoscopeList() {

    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/horoscope',
        });

        yield put(horoscopeListSuccess(response));
    } catch (e) {
        console.log('error', e);
        // message.error(e.response ? e.response.data.message : e);
        yield put(horoscopeListFailure(e.response ? e.response.data.message : e));
    }
}

function* findUserHoroscope() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/horoscope/find-user-horoscope',
        });

        yield put(findUserHoroscopeSuccess(response));
    } catch (e) {
        message.error('Error', e.response?.data?.message || 'Unable to find horoscope');
        yield put(findUserHoroscopeFailure(e.response ? e.response.data.message : e));
    }
}

function* userActiveHoroscopes(action) {
    const { refresh } = action.payload || false;
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `horoscope/user-horoscopes?refresh=${refresh ? '1' : '0'}`,
        });
        yield put(userActiveHoroscopesSuccess(response.data));
    } catch (e) {
        // showError('Error', e.response ? e.response.data.message : e);
        yield put(userActiveHoroscopesFailure(e.response ? e.response.data.message : e));
    }
}

function* checkIfLive() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'horoscope/live-streaming-status',
        });
        yield put(checkIfLiveSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(checkIfLiveFailure(e.response ? e.response.data.message : e));
    }
}

function* userPreviousHoroscopes() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'horoscope/user-horoscopes?refresh=1&previous=1',
        });
        yield put(userPreviousHoroscopesSuccess(response.data));
    } catch (e) {
        // showError('Error', e.response ? e.response.data.message : e);
        yield put(userPreviousHoroscopesFailure(e.response ? e.response.data.message : e));
    }
}

function* getNewSingleHoroscope(action) {
    const { timePeriod, horoscopeSign, userAuthenticated } = action.payload;
    const url = userAuthenticated ? `/horoscope/user-new-single-horoscope/${timePeriod}/${horoscopeSign}` : `/horoscope/new-single-horoscope/${timePeriod}/${horoscopeSign}`;
    try {
        const response = yield call(userAuthenticated ? fetchApiAuth : fetchApi, {
            method: 'GET',
            url,
        });

        yield put(getNewSingleHoroscopeSuccess(response));
    } catch (e) {
        message.error('Error', e.response?.data?.message || 'Unable to find horoscope');
        yield put(getNewSingleHoroscopeFailure(e.response ? e.response.data.message : e));
    }
}

function* moonsignLookup(action) {
    const { dob, placeOfBirth } = action.payload.data;
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: '/moonsign-lookup',
            body: {
                date_of_birth: dob,
                place_of_birth: placeOfBirth,
            },
        });

        const { data } = response;

        yield put(moonsignLookupSuccess(data.moonsign));
    } catch (e) {
        yield put(moonsignLookupFailure(e.response ? e.response.data.message : e));
    }
}

export default function* authSaga() {
    yield* [
        takeEvery(HOROSCOPE_LIST_REQUEST, horoscopeList),
        takeEvery(FIND_USER_HOROSCOPE_REQUEST, findUserHoroscope),
        takeEvery(USER_ACTIVE_HOROSCOPES_REQUEST, userActiveHoroscopes),
        takeEvery(CHECK_IF_LIVE_REQUEST, checkIfLive),
        takeEvery(USER_PREVIOUS_HOROSCOPES_REQUEST, userPreviousHoroscopes),
        takeEvery(GET_NEW_SINGLE_HOROSCOPE_REQUEST, getNewSingleHoroscope),
        takeEvery(MOONSIGN_LOOKUP_REQUEST, moonsignLookup),
    ];
}

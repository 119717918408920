import { Record } from 'immutable';
import { findIndex } from 'underscore';

const {
    GET_PAYMENT_PLANS_REQUEST,
    GET_PAYMENT_PLANS_SUCCESS,
    GET_PAYMENT_PLANS_FAILURE,

    SELECT_PLAN_REQUEST,

    ADD_PAYMENT_CARD_REQUEST,
    ADD_PAYMENT_CARD_SUCCESS,
    ADD_PAYMENT_CARD_FAILURE,

    SAVE_PAYMENT_CARD_REQUEST,
    SAVE_PAYMENT_CARD_SUCCESS,
    SAVE_PAYMENT_CARD_FAILURE,

    BUY_SUBSCRIPTION_REQUEST,
    BUY_SUBSCRIPTION_SUCCESS,
    BUY_SUBSCRIPTION_FAILURE,

    BUY_CONSULTATION_REQUEST,
    BUY_CONSULTATION_SUCCESS,
    BUY_CONSULTATION_FAILURE,

    GET_USER_HISTORIC_SUBSCRIPTIONS_REQUEST,
    GET_USER_HISTORIC_SUBSCRIPTIONS_SUCCESS,
    GET_USER_HISTORIC_SUBSCRIPTIONS_FAILURE,

    GET_PAYMENT_CARDS_REQUEST,
    GET_PAYMENT_CARDS_SUCCESS,
    GET_PAYMENT_CARDS_FAILURE,

    APPLY_COUPON_CODE_REQUEST,
    APPLY_COUPON_CODE_SUCCESS,
    APPLY_COUPON_CODE_FAILURE,

    CANCEL_SUBSCRIPTION_REQUEST,
    CANCEL_SUBSCRIPTION_SUCCESS,
    CANCEL_SUBSCRIPTION_FAILURE,

    SAVE_ONE_OFF_PAYMENT_REQUEST,

    BUY_ONE_OFF_REQUEST,
    BUY_ONE_OFF_SUCCESS,
    BUY_ONE_OFF_FAILURE,

    ADD_LIFE_EVENT_REQUEST,

    BUY_LIFE_EVENT_REQUEST,
    BUY_LIFE_EVENT_SUCCESS,
    BUY_LIFE_EVENT_FAILURE,

    SET_SELECTED_ONE_OFF_PARAMS,
    CLEAR_SELECTED_ONE_OFF_PARAMS,
} = require('./paymentActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    plans: [],
    lifeEvents: [],
    selectedPlan: {},
    paymentCards: [],
    isCheckingCouponCode: false,
    couponCode: {},
    userHistoricSubscriptions: [],
    selectedOneOffPaymentPlan: {},
    selectedOneOffParams: {},
});

const initialState = new InitialState();

/**
 * ## galleryReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function galleryReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

    switch (type) {
    case GET_PAYMENT_PLANS_REQUEST:
    case ADD_PAYMENT_CARD_REQUEST:
    case SAVE_PAYMENT_CARD_REQUEST:
    case BUY_SUBSCRIPTION_REQUEST:
    case BUY_CONSULTATION_REQUEST:
    case GET_USER_HISTORIC_SUBSCRIPTIONS_REQUEST:
    case GET_PAYMENT_CARDS_REQUEST:
    case BUY_ONE_OFF_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case GET_PAYMENT_PLANS_SUCCESS: {
        return state.set('isFetching', false)
            .set('plans', payload.data.plans);
    }

    case APPLY_COUPON_CODE_REQUEST:
        return state.set('isCheckingCouponCode', true)
            .set('error', null);

    case APPLY_COUPON_CODE_SUCCESS:
        return state.set('isCheckingCouponCode', false)
            .set('couponCode', payload);

    case APPLY_COUPON_CODE_FAILURE:
        return state.set('isCheckingCouponCode', false)
            .set('couponCode', payload);

    case CANCEL_SUBSCRIPTION_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case SELECT_PLAN_REQUEST: {
        let { selectedPlan } = state;
        const { plans } = state;

        const singlePlanKey = findIndex(plans, (val) => val.id === payload);
        if (singlePlanKey !== -1) {
            selectedPlan = plans[singlePlanKey];
        }

        return state.set('selectedPlan', selectedPlan);
    }

    case SAVE_ONE_OFF_PAYMENT_REQUEST:
        return state.set('selectedOneOffPaymentPlan', payload);

    case BUY_ONE_OFF_SUCCESS:
        return state.set('isFetching', false).set('selectedOneOffParams', {});

    case ADD_PAYMENT_CARD_SUCCESS: {
        return state.set('isFetching', false);
    }

    case CANCEL_SUBSCRIPTION_SUCCESS:
        return state.set('isFetching', false)
            .set('userHistoricSubscriptions', payload.data);

    case CANCEL_SUBSCRIPTION_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    case BUY_SUBSCRIPTION_SUCCESS:
    case BUY_CONSULTATION_SUCCESS: {
        return state.set('isFetching', false);
    }

    case GET_USER_HISTORIC_SUBSCRIPTIONS_SUCCESS: {
        return state.set('userHistoricSubscriptions', payload.data)
            .set('isFetching', false);
    }

    case GET_PAYMENT_CARDS_SUCCESS:
    case SAVE_PAYMENT_CARD_SUCCESS: {
        return state.set('isFetching', false)
            .set('paymentCards', payload.data.cards);
    }

    case BUY_ONE_OFF_FAILURE:
    case GET_PAYMENT_PLANS_FAILURE:
    case ADD_PAYMENT_CARD_FAILURE:
    case SAVE_PAYMENT_CARD_FAILURE:
    case BUY_SUBSCRIPTION_FAILURE:
    case BUY_CONSULTATION_FAILURE:
    case GET_USER_HISTORIC_SUBSCRIPTIONS_FAILURE:
    case GET_PAYMENT_CARDS_FAILURE: {
        return state.set('isFetching', false)
            .set('error', payload);
    }

    case ADD_LIFE_EVENT_REQUEST: {
        if (payload === 'reset') {
            return state.set('lifeEvents', []);
        }
        const { lifeEvents } = state;
        const newLifeEvents = [...lifeEvents];
        const lifeEventIndex = findIndex(newLifeEvents, (val) => val === payload);
        if (lifeEventIndex !== -1) {
            newLifeEvents.splice(lifeEventIndex, 1);
        } else {
            newLifeEvents.push(payload);
        }
        return state.set('lifeEvents', newLifeEvents);
    }

    case BUY_LIFE_EVENT_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case BUY_LIFE_EVENT_SUCCESS: {
        return state.set('isFetching', false);
    }

    case BUY_LIFE_EVENT_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    case SET_SELECTED_ONE_OFF_PARAMS:
        return state.set('selectedOneOffParams', payload);

    case CLEAR_SELECTED_ONE_OFF_PARAMS:
        return state.set('selectedOneOffParams', {});

    default:
        return state;
    }
}

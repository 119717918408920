/* eslint-disable quote-props */
import { message } from 'antd';
import { put, call, takeEvery } from 'redux-saga/effects';
import { fetchApiAuth, fetchApi } from '../utils/api';
import history from '../utils/history';

import {
    getConsultationsSuccess,
    getConsultationsFailure,

    saveConsultationSuccess,
    saveConsultationFailure,

    getSelectedConsultationSuccess,
    getSelectedConsultationFailure,

} from './consultationActions';

const {
    GET_CONSULTATIONS_REQUEST,
    SAVE_CONSULTATION_REQUEST,
    GET_SELECTED_CONSULTATION_REQUEST,
} = require('./consultationActions').constants;

function* getConsultations() {

    try {
        const response = yield call(fetchApi, {
            method: 'GET',
            url: '/consultation',
        });
        yield put(getConsultationsSuccess(response));
    } catch (e) {
        console.log('error', e);
        // message.error(e.response ? e.response.data.message : e);
        yield put(getConsultationsFailure(e.response ? e.response.data.message : e));
    }
}

function* getSelectedConsultation(payload) {
    const { slug, selectedLang } = payload.payload;
    try {
        const response = yield call(fetchApi, {
            method: 'GET',
            url: `consultation/selected-consultation?slug=${slug}&selectedLang=${selectedLang}`,
        });
        if (response.data && response.data.error) {
            message.error(response.data.error);
            history.replace('/consultations');
        }
        yield put(getSelectedConsultationSuccess(response));
    } catch (e) {
        yield put(getSelectedConsultationFailure(e.response ? e.response.data.message : e));
    }
}

function* saveConsultation({ payload }) {

    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/user-consultation/update-web',
            data: payload,
        });

        yield put(saveConsultationSuccess(response));
    } catch (e) {
        console.log('error_occurred', 'Error occurred while saving consultation', e);
        // message.error(e.response ? e.response.data.message : e);
        yield put(saveConsultationFailure(e.response ? e.response.data.message : e));
    }
}

export default function* consultationsSaga() {
    yield* [
        takeEvery(GET_CONSULTATIONS_REQUEST, getConsultations),
        takeEvery(SAVE_CONSULTATION_REQUEST, saveConsultation),
        takeEvery(GET_SELECTED_CONSULTATION_REQUEST, getSelectedConsultation),
    ];
}

// import { useDispatch } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { Button, Row, Col, message } from 'antd';
import { isEmpty, isNull } from 'underscore';
import { Link } from 'react-router-dom';
import Input from 'antd/lib/input/Input';
import { getTranslation, getSelectedTranslation } from '../../core/utils/helpers';
import Main from '../../components/layout/Main';
import usePayment from '../../core/payment/usePayment';
import history from '../../core/utils/history';
import useLanguage from '../../core/language/useLanguage';

import imageDiamond from '../../assets/images/subscriptions/diamond.png';
import imageSilver from '../../assets/images/subscriptions/silver.png';
import imageGold from '../../assets/images/subscriptions/gold.png';
import star from '../../assets/images/subscriptions/star.png';

import './horoscope.scss';

const PaymentPlanSelect = () => {
    // const dispatch = useDispatch();

    const { getPaymentPlansRequest, payment, selectPlanRequest, applyCouponCodeRequest } = usePayment();
    const { language } = useLanguage();
    const [promoCode, setPromoCode] = useState('');

    useEffect(() => {
        getPaymentPlansRequest();
    }, []);

    useEffect(() => {
        if (isEmpty(payment.selectedPlan) && !isEmpty(payment.plans)) {
            payment.plans.forEach(singlePaymentPlan => {
                if (singlePaymentPlan?.saving_banner_flag === '10') {
                    selectPlanRequest(singlePaymentPlan.id);
                }
            });
        }
    }, []);

    const menu = [{ label: 'Item 1', link: '/abc', iconName: 'home', style: { fontSize: 24, color: 'red' } }];

    const handleSubmit = () => {
        let error = false;

        if (isEmpty(payment.selectedPlan)) {
            error = true;
            message.warning('Please select payment plan');
        }

        if (!error) {
            history.replace('/order-confirm');

        }
    };

    const handleCouponClick = () => {

        if (isEmpty(payment.selectedPlan)) {
            message.warning(getTranslation(language, 'select_payment_plan', 'Please select payment plan'));
            return;
        }

        if (!isNull(promoCode) && promoCode !== '' && promoCode !== 'undefined') {
            applyCouponCodeRequest({ promoCode, selectedPlan: payment?.selectedPlan?.id, selectedLang: language?.selectedLanguage?.id });
            return;
        }
        message.warning(getTranslation(language, 'invalid_coupon_text', 'Please enter valid promo code'));
    };

    const subscriptionPlan = () => {
        const arr = [];

        payment.plans.map(paymentPlan => {
            const translation = getSelectedTranslation(paymentPlan?.translations, language?.selectedLanguage?.id);
            let active = false;
            if (paymentPlan.id === payment.selectedPlan?.id) {
                active = true;
            }
            arr.push(
                <Col className="payment-plan-col" xs={24} sm={24} md={8} lg={8} xl={8}>
                    <div className={`subscription-box ${active === true ? 'active' : ''}`} onClick={() => selectPlanRequest(paymentPlan.id)}>
                        <div className="subscription-box-text">
                            {paymentPlan?.horoscope_content_frequency === 1 ? <img className="payment-plan-img" src={imageSilver} alt="Silver" /> : null}
                            {paymentPlan?.horoscope_content_frequency === 3 ? <img className="payment-plan-img" src={imageDiamond} alt="Diamond" /> : null}
                            {paymentPlan?.horoscope_content_frequency === 2 ? <img className="payment-plan-img" src={imageGold} alt="Gold" /> : null}
                            {translation?.title}
                            {paymentPlan?.saving_banner_flag === '10' ? <img className="payment-plan-star-img" src={star} alt="Star" /> : null}
                        </div>
                        <div className="subscription-box-description">
                            {translation?.period_title}
                        </div>

                        <Button className="subscription-box-button" onClick={() => selectPlanRequest(paymentPlan.id)}> Get {translation?.title} for £{paymentPlan.amount} {translation?.frequency_title} </Button>

                    </div>
                </Col>,
            );

            return true;
        });

        return arr;
    };

    return (
        <Main
            className="payment-plan-select-page"
            title={<div>Welcome</div>}
            headerTitle={(
                <div>
                    <img style={{ height: 70, width: 70 }}
                        src="https://prakashastrologer-19e87.kxcdn.com/wp-content/uploads/2020/05/PrakashAstrologerLogo.svg"
                        alt="logo" />
                </div>
            )}
            menu={menu}
            menuWidth={350}
            menuPlacement="right"
            footerContent={<div style={{ lineHeight: '30px', fontSize: '16px', float: 'right' }}>&copy; copyright 2019</div>}
            showFooter={false}
            backLink={(
                <Link to="/dashboard" className="backlink">
                    {getTranslation(language, 'horoscopes_header_title', 'Horoscopes')}
                </Link>
            )}>
            <div className="center-align">
                <h1 className="title-text mb-16" style={{ fontSize: '20px' }}>   {getTranslation(language, 'payment_plan_select_subscription_title', 'Select your Subscription')}
                </h1>

                <Row gutter={[20]} className="payment-plans-row">
                    {subscriptionPlan()}
                </Row>

                {!payment.isCheckingCouponCode
                    ? (
                        <Button onClick={handleSubmit} style={{ marginTop: '5vh', marginBottom: '30px' }} htmlType="submit" type="primary" className="ant-btn-yellow min-button-width-height">
                            {getTranslation(language, 'payment_plan_select_button_title', 'Select')}
                        </Button>
                    )
                    : (
                        <Button loading style={{ marginTop: '5vh', marginBottom: '30px' }} htmlType="submit" type="primary" className="ant-btn-yellow min-button-width-height">
                            {getTranslation(language, 'payment_plan_select_button_title', 'Select')}
                        </Button>
                    )}

                <div className="coupon-box">
                    <div className="coupon-text-box">
                        <div className="coupon-box-title"> {getTranslation(language, 'get_promotion_code_label', 'Promo Code')} </div>
                        <div className="coupon-box-desc">{getTranslation(language, 'enter_promo_code_promotion_code_desc', 'Enter your promo code for free access')} </div>
                    </div>
                    <Input
                        type="text"
                        placeholder={getTranslation(language, 'get_promotion_code_label', 'Promo Code')}
                        onChange={(e) => setPromoCode(e.target.value)} />
                </div>
                {!payment.isCheckingCouponCode
                    ? (
                        <Button onClick={() => handleCouponClick()} style={{ marginTop: '5vh', marginBottom: '30px' }} htmlType="submit" type="primary" className="ant-btn-yellow min-button-width-height">
                            {getTranslation(language, 'apply_coupon_button', 'Apply')}
                        </Button>
                    )
                    : (
                        <Button loading style={{ marginTop: '5vh', marginBottom: '30px' }} htmlType="submit" type="primary" className="ant-btn-yellow min-button-width-height">
                            {getTranslation(language, 'apply_coupon_button', 'Apply')}
                        </Button>
                    )}
                <div>
                    <Link style={{ fontFamily: 'Noto Sans', color: '#E1BF40' }} to="/dashboard">{getTranslation(language, 'select_subscription_screen_skip_button_title', 'Skip')}  </Link>
                </div>
            </div>
        </Main>
    );
};

export default PaymentPlanSelect;

import React from 'react';
import useLanguage from '../../core/language/useLanguage';
import { getTranslation, getSelectedTranslation } from '../../core/utils/helpers';
import useUser from '../../core/user/useUser';
import './generatedMoonsign.scss';

const GeneratedMoonsign = () => {
    const { language } = useLanguage();
    const { user } = useUser();
    const { userData } = user;

    const horoscopeTranslation = getSelectedTranslation(userData?.generated_horoscope?.translations, language?.selectedLanguage?.id);
    const iconUrl = userData?.generated_horoscope?.mediaUrl;

    if (!userData?.generated_horoscope) {
        return null;
    }

    return (
        <div className="generated-moonsign-menu-container">
            <h5 className="moonsign-heading">{`${getTranslation(language, 'your_generated_moonsign', 'Your Generated Moonsign')}`}</h5>
            <div className="moonsign-container">
                <img className="moonsign-icon" src={iconUrl} alt="moonsign-icon" />
                <span className="moonsign-name">{horoscopeTranslation.name}</span>
            </div>
        </div>
    );
};

export default GeneratedMoonsign;

/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { isAndroid } from 'react-device-detect';
import { useSelector } from 'react-redux';
import DeepLinkModal from '../../components/DeepLinkModal';
import Main from '../../components/layout/Main';

const DeepLinkHandler = () => {
    const location = useLocation();
    const history = useHistory();
    const { isLoggedIn } = useSelector((state) => state.user);
    const [showModal, setShowModal] = useState(false);
    const redirectPath = location.pathname.replace('/deeplink', '');
    const params = new URLSearchParams(location.search);
    const authParam = params.get('auth');
    const requiresAuth = authParam === 'true';

    const cleanParams = new URLSearchParams(location.search);
    cleanParams.delete('auth');
    const cleanDeepLinkURL = redirectPath + (cleanParams.toString() ? `?${cleanParams.toString()}` : '');

    useEffect(() => {
        window.sessionStorage.setItem('deepLinkRedirect', cleanDeepLinkURL);

        if (isAndroid) {
            setShowModal(true);
        } else if (requiresAuth) {
            if (isLoggedIn) {
                history.push(cleanDeepLinkURL);
            } else {
                history.push('/');
            }
        } else {
            window.sessionStorage.removeItem('deepLinkRedirect');
            history.push(cleanDeepLinkURL);
        }
    }, [cleanDeepLinkURL, location.search, isLoggedIn, history]);

    return (
        <Main
            title={<div>Welcome</div>}
            headerTitle={(
                <div>
                    <img
                        style={{ height: 70, width: 70 }}
                        src="https://prakashastrologer-19e87.kxcdn.com/wp-content/uploads/2020/05/PrakashAstrologerLogo.svg"
                        alt="logo" />
                </div>
            )}
            showFooter={false}>
            {showModal && (
                <DeepLinkModal onClose={() => setShowModal(false)} requiresAuth={requiresAuth} />
            )}
        </Main>
    );
};

export default DeepLinkHandler;

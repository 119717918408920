import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useLanguage from '../../core/language/useLanguage';
import { getTranslation } from '../../core/utils/helpers';
import Main from '../../components/layout/Main';
import MoonsignLookupForm from '../../components/Forms/MoonsignLookupForm';
import './moonsignLookup.scss';
import { resetMoonsignLookup } from '../../core/horoscope/horoscopeActions';
import DisplayMoonsign from '../../components/DisplayMoonsign';
import history from '../../core/utils/history';

const MoonsignLookup = () => {
    const { language } = useLanguage();

    const dispatch = useDispatch();

    const toolbar = [{ label: 'Item 1', link: '/abc', iconName: 'home' }];
    const menu = [{ label: 'Item 1', link: '/abc', iconName: 'home', style: { fontSize: 24, color: 'red' } }];

    const { searchedMoonsign } = useSelector((state) => state.horoscope);

    const isSearchedMoonsign = Object.keys(searchedMoonsign).length > 0;

    useEffect(() => {
        dispatch(resetMoonsignLookup());
    }, []);

    return (
        <Main
            title={<div>Welcome</div>}
            headerTitle={(
                <div>
                    <img style={{ height: 70, width: 70 }}
                        src="https://prakashastrologer-19e87.kxcdn.com/wp-content/uploads/2020/05/PrakashAstrologerLogo.svg"
                        alt="logo" />
                </div>
            )}
            menu={menu}
            menuWidth={350}
            menuPlacement="right"
            toolbar={toolbar}
            footerContent={<div style={{ lineHeight: '30px', fontSize: '16px', float: 'right' }}>&copy; copyright 2019</div>}
            showHeader
            showFooter={false}
            backLink={(<div style={{ display: 'flex' }}><div onClick={() => history.goBack()} className="backlink" style={{ marginBottom: '24px', cursor: 'pointer' }} /></div>)}>
            <div>
                <h1 className="title-text" style={{ marginBottom: '40px' }}>{getTranslation(language, 'moonsign_lookup_title', 'Moonsign Lookup')}</h1>
            </div>
            {isSearchedMoonsign ? (
                <DisplayMoonsign />
            ) : (
                <MoonsignLookupForm />
            )}
        </Main>
    );
};

export default MoonsignLookup;

import { PropTypes } from 'prop-types';
import React from 'react';
import './oneOffPurchaseButton.scss';
import { useSelector, useDispatch } from 'react-redux';
import history from '../../core/utils/history';
import useLanguage from '../../core/language/useLanguage';
import { getTranslation } from '../../core/utils/helpers';
import { setSelectedOneOffParams } from '../../core/payment/paymentActions';

const OneOffPurchaseButton = ({ btnClass, title, horoscopeDataId, horoscopeType }) => {
    const { language } = useLanguage();
    const user = useSelector(state => state.user);
    const { isLoggedIn } = user;
    const dispatch = useDispatch();
    const handleOneOffSubmit = () => {
        dispatch(setSelectedOneOffParams({ horoscopeDataId, horoscopeType }));
        if (isLoggedIn) {
            history.push({ pathname: '/one-off-confirmation' });
        } else {
            history.push('/?redirect=one-off-confirmation');
        }
    };

    return (
        <div style={{ marginTop: 10, width: btnClass === 'invert' ? '100%' : '100%', alignItems: 'center' }}>
            <button className="submit-btn-yellow" style={{ marginLeft: btnClass === 'invert' ? '0' : '0', width: btnClass === 'invert' ? '75%' : '50%' }} type="button" href="#" onClick={() => { handleOneOffSubmit(); }}>
                {getTranslation(language, 'buy', 'Buy')}  {title} {getTranslation(language, 'premium_horoscope', 'Premium Horoscope')}
            </button>
        </div>
    );
};

OneOffPurchaseButton.propTypes = {
    horoscopeDataId: PropTypes.number.isRequired,
    horoscopeType: PropTypes.string.isRequired,
    btnClass: PropTypes.string,
    title: PropTypes.string,
};

OneOffPurchaseButton.defaultProps = {
    btnClass: 'normal',
    title: '',
};

export default OneOffPurchaseButton;

import { PropTypes } from 'prop-types';
import React, { useEffect } from 'react';
import { findIndex } from 'underscore';
import { useSelector } from 'react-redux';
import './subscriptionButton.scss';
import history from '../../core/utils/history';
import useLanguage from '../../core/language/useLanguage';
import { getTranslation } from '../../core/utils/helpers';
import useAuth from '../../core/user/useUser';
import useHoroscope from '../../core/horoscope/useHoroscope';
import usePayment from '../../core/payment/usePayment';

const SubscriptionButton = ({ horoscopeContentFrequency, selectDefaultUserHoroscope, btnClass }) => {

    const { language } = useLanguage();
    const payment = useSelector(state => state.payment);
    const { selectSingleHoroscopeRequest, selectHoroscopePlanRequest } = useHoroscope();
    const { user } = useAuth();
    const { selectPlanRequest } = usePayment();
    const [buttonText, setButtonText] = React.useState('');

    useEffect(() => {
        if (horoscopeContentFrequency === '1' || horoscopeContentFrequency === 1) {
            setButtonText(getTranslation(language, 'button_sign_up_to_silver_package_title', 'Sign up to the Silver Package'));
        }

        if (horoscopeContentFrequency === '2' || horoscopeContentFrequency === 2) {
            setButtonText(getTranslation(language, 'button_sign_up_to_gold_package_title', 'Sign up to our Gold Package'));
        }

        if (horoscopeContentFrequency === '3' || horoscopeContentFrequency === 3) {
            setButtonText(getTranslation(language, 'button_sign_up_to_diamond_package_title', 'Sign up to our Diamond Package'));
        }

    }, [horoscopeContentFrequency]);

    const redirectToPayment = () => {
        let paymentPlan = {};
        const paymentPlanIndex = findIndex(payment.plans, (val) => val.horoscope_content_frequency === Number(horoscopeContentFrequency));
        if (paymentPlanIndex !== -1) {
            paymentPlan = payment.plans[paymentPlanIndex];
        }

        selectPlanRequest(paymentPlan.id);
        selectHoroscopePlanRequest(horoscopeContentFrequency);

        if (selectDefaultUserHoroscope === true) {
            selectSingleHoroscopeRequest(user?.userData?.generated_horoscope);
        }

        history.push({ pathname: '/moonsign-select', state: { hideModal: true } });

    };

    return (
        <div style={{ marginTop: 2, width: btnClass === 'invert' ? '100%' : '90%', alignItems: 'center' }}>
            <button className="submit-btn-yellow" style={{ marginLeft: btnClass === 'invert' ? '0' : '25%', width: btnClass === 'invert' ? '75%' : '60%', height: 60 }} type="button" href="#" onClick={() => { redirectToPayment(); }}>{buttonText}</button>
        </div>
    );

};

SubscriptionButton.propTypes = {
    horoscopeContentFrequency: PropTypes.any,
    selectDefaultUserHoroscope: PropTypes.bool,
    btnClass: PropTypes.string,

};

SubscriptionButton.defaultProps = {
    horoscopeContentFrequency: 1,
    selectDefaultUserHoroscope: false,
    btnClass: 'normal',

};

export default SubscriptionButton;

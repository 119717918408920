/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'underscore';
import { Link } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Spin, Modal } from 'antd';
import Main from '../../components/layout/Main';
import './singleHoroscope.scss';
import { getNewSingleHoroscopeRequest } from '../../core/horoscope/horoscopeActions';
import HoroscopeCarousel from '../../components/HoroscopeCarousel';
import useLanguage from '../../core/language/useLanguage';
import { getSelectedTranslation } from '../../core/utils/helpers';
import OneOffPurchaseButton from '../../components/OneOffPurchaseButton';
import { selectSelectedHoroscope } from '../../core/horoscope/horoscopeActions';
import YellowThemeButton from '../../components/YellowThemeButton';

const SingleHoroscope = (props) => {
    const [elRefs, setElRefs] = useState([]);
    const [showAppStoreLink, setShowAppStoreLink] = useState(false);

    const horoscope = useSelector(state => state.horoscope);
    const { isFetching, singleHoroscope } = horoscope;
    const { userData } = useSelector(state => state.user);

    const [activeHoroscopeIndex, setActiveHoroscopeIndex] = useState(0);

    const { language } = useLanguage();
    const { timePeriod, horoscopeSign } = props.match.params;
    const user = useSelector(state => state.user);
    const { isLoggedIn } = user;

    const dispatch = useDispatch();

    const isAndroidDevice = () => {
        return /Android/i.test(navigator.userAgent);
    };

    useEffect(() => {
        const getHoroscopeData = async () => {
            try {
                const userAuthenticated = isLoggedIn;
                dispatch(getNewSingleHoroscopeRequest({ timePeriod, horoscopeSign, userAuthenticated }));
            } catch (error) {
                console.log(error.message);
            }
        };
        getHoroscopeData();
        window.scrollTo(0, 0);
        if (isAndroidDevice()) {
            setShowAppStoreLink(true);
        }
    }, []);

    useEffect(() => {
        if (singleHoroscope?.horoscopeData?.length > 0) {
            setActiveHoroscopeIndex(singleHoroscope?.horoscopeData?.length - 1);
        }
    }, [singleHoroscope]);

    useEffect(() => {
        if (singleHoroscope?.horoscopeData && activeHoroscopeIndex < singleHoroscope.horoscopeData.length) {
            dispatch(selectSelectedHoroscope(singleHoroscope?.horoscopeData[activeHoroscopeIndex]));
        }
    }, [activeHoroscopeIndex]);

    useEffect(() => {
        setElRefs((elRefs) =>
            Array(singleHoroscope?.horoscopeData?.length)
                .fill()
                .map((_, i) => elRefs[i] || React.createRef())
        );
    }, [singleHoroscope]);

    const handleOpenInApp = () => {
        const intentUrl = `intent://HoroscopeDetail?timePeriod=${timePeriod}&horoscopeSign=${horoscopeSign}#Intent;scheme=prakashastrology;package=com.prakash.astrology;S.browser_fallback_url=https://play.google.com/store/apps/details?id=com.prakash.astrology;end`;
        window.location.href = intentUrl;
    };

    const scrollToPart = (index) => {
        if (elRefs[index] && elRefs[index].current) {
            elRefs[index].current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    // if single horoscope is an empty object, return null
    if (Object.keys(singleHoroscope).length === 0) {
        return null;
    }

    if (!singleHoroscope?.horoscopeData || singleHoroscope?.horoscopeData?.length === 0) {
        return null;
    }

    const activeHoroscope = singleHoroscope?.horoscopeData[activeHoroscopeIndex];
    const horoscopeTranslation = getSelectedTranslation(activeHoroscope?.translations, language?.selectedLanguage?.id);
    // set as false if parts are not empty
    const isUserSubscribed = userData.active_subscriptions?.some(
        subscription => subscription.status === "active" && subscription.horoscope.key.toLowerCase() === horoscopeSign.toLowerCase()
    );
    const showBuyButton = !isUserSubscribed && !activeHoroscope?.parts?.length && activeHoroscope?.type !== '3';
    const videoCode = activeHoroscope?.parts?.length > 0 ? horoscopeTranslation?.video_link : horoscopeTranslation?.video_link_preview;

    if (!horoscopeTranslation && !activeHoroscope) {
        return null;
    }

    const renderHoroscopePart = (partData, partDataIndex) => {
        const horoscopePartLanguage = getSelectedTranslation(partData?.translations, language?.selectedLanguage?.id);
        return (
            <div
                className="chapter-block"
                key={`single-part-${partData.id}`}
                ref={elRefs[partDataIndex]}>
                <h3>{horoscopePartLanguage?.name}</h3>
                {horoscopePartLanguage?.video_link
                    && (
                        <div className="video-block">
                            <iframe src={`https://www.youtube.com/embed/${horoscopePartLanguage?.video_link}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                        </div>
                    )}
                <div>{horoscopePartLanguage?.description}</div>
            </div>
        );
    };

    const getCarouselTitle = (horoscopeData) => {
        const date = horoscopeData?.date;
        if (!date || !moment(date).isValid()) {
            return 'Date not found';
        }

        if (horoscopeData?.type === '1') {
            return `${moment(date).format('MMMM DD')} - ${moment(date).add(6, 'day').format('MMMM DD')}`;
        }
        if (horoscopeData?.type === '2') {
            return `${moment(date).format('MMMM YYYY')}`;
        }
        return 'Date not found';
    };

    const carouselData = singleHoroscope?.horoscopeData?.map((item, index) => ({
        id: index + 1,
        onClick: () => setActiveHoroscopeIndex(index),
        title: getCarouselTitle(item),
    }));

    const getHoroscopeType = () => {
        if (activeHoroscope?.type === '1') {
            return 'Weekly Horoscope';
        }
        if (activeHoroscope?.type === '2') {
            return 'Monthly Horoscope';
        }
        return 'Annual Horoscope';
    };

    return (
        <Main
            className="align-center"
            title={<div>Welcome</div>}
            headerTitle={(
                <div>
                    <img style={{ height: 70, width: 70 }}
                        src="https://prakashastrologer-19e87.kxcdn.com/wp-content/uploads/2020/05/PrakashAstrologerLogo.svg"
                        alt="logo" />
                </div>
            )}
            menuWidth={350}
            menuPlacement="right"
            displayRightMenu
            floatingHeader
            showFooter={false}
            scrollTo={scrollToPart}
            backLink={(
                <div style={{ display: 'flex' }}>
                    <Link to="/dashboard" className="backlink" style={{ marginBottom: '24px' }} />
                </div>
            )}>
            {isFetching ? (
                <div className="loaderSpacer">
                    <Spin size="large" />
                </div>
            ) : (
                <>
                    {showAppStoreLink && (
                        <Modal
                            visible={showAppStoreLink}
                            onCancel={() => setShowAppStoreLink(false)}
                            footer={null}
                            className="open-in-app-modal"
                        >
                            <p className="open-in-app-modal-title">View this horoscope in the app?</p>
                            <div style={{ display: 'flex', justifyContent: 'space-between', gap: 10 }}>
                                <YellowThemeButton
                                    onClick={() => setShowAppStoreLink(false)}
                                >
                                    Continue in Browser
                                </YellowThemeButton>
                                <YellowThemeButton
                                    onClick={handleOpenInApp}
                                >
                                    Open in App
                                </YellowThemeButton>
                            </div>
                        </Modal>
                    )}
                    <div className="content single-horoscope-content">
                        <div className="header">
                            {activeHoroscope?.type !== '3' && <div className="horoscope-carousel-wrapper"><HoroscopeCarousel data={carouselData} activeSlide={activeHoroscopeIndex + 1} /></div>}
                            <h1 className="page-title">{horoscopeTranslation?.name?.split(' ')[0] || 'Default Name'}</h1>
                            <h3 className="page-subtitle">{getHoroscopeType()}</h3>
                            {showBuyButton && (
                                <div className="one-off-buy-btn-wrapper">
                                    <OneOffPurchaseButton
                                        btnClass=""
                                        title={getCarouselTitle(activeHoroscope)}
                                        horoscopeDataId={activeHoroscope?.id}
                                        horoscopeType={activeHoroscope?.type} />
                                </div>
                            )}
                        </div>
                        <div className="single-horoscope-content-wrapper">
                            {!isEmpty(videoCode) ? (
                                <div className="video-block">
                                    <iframe src={`https://www.youtube.com/embed/${videoCode}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                                </div>
                            ) : null }
                            {activeHoroscope?.parts.length > 0 ? (
                                <div className="horoscope-parts-wrapper">
                                    {activeHoroscope?.parts
                                        .filter(part => isUserSubscribed || part?.current_price <= 0)
                                        .map((part, index) => renderHoroscopePart(part, index))}
                                </div>
                            ) : (
                                <div className="horoscope-description-block">{horoscopeTranslation?.description_preview}</div>
                            )}
                        </div>
                    </div>
                </>
                

            )}
        </Main>
    );
};

export default SingleHoroscope;

SingleHoroscope.propTypes = {
    match: PropTypes.object.isRequired,
};

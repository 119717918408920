import React from 'react';
import { useDispatch } from 'react-redux';
import { Form, Input, Button, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import useLanguage from '../../../core/language/useLanguage';
import { getTranslation } from '../../../core/utils/helpers';
import * as userActions from '../../../core/user/userActions';
import useUser from '../../../core/user/useUser';
import '../LoginForm/loginForm.scss';

const PasswordResetForm = () => {
    const dispatch = useDispatch();
    const { language } = useLanguage();
    const { user } = useUser();

    const handleSubmit = (values) => {
        dispatch(userActions.forgotPasswordRequest({
            email: values.email,
        }));
    };

    return (
        <div>
            <div className="loginform-wrap">
                <div className="loginform-container">
                    <Form onFinish={handleSubmit} layout="vertical">
                        <div className="form-inner-wrapper">
                            <h1>{getTranslation(language, 'forgotten_password_screen_title', 'Forgotten Password')}</h1>
                            <Row>
                                <Col xs={24}>
                                    <Form.Item label={getTranslation(language, 'form_label_email', 'Email')}
                                        name="email"
                                        rules={[
                                            {
                                                type: 'email',
                                                message: getTranslation(language, 'sign_up_email_validation', 'Not a valid email!'),
                                            },
                                            {
                                                required: true,
                                                message: getTranslation(language, 'sign_up_email_empty', 'Please input your email!'),
                                            },
                                        ]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                        <Row>
                            <Col xs={24}>
                                {user.isFetching ? (
                                    <Button type="primary" htmlType="submit" className="ant-btn-yellow" loading>
                                        {getTranslation(language, 'forgotten_password_submit_button_text', 'Submit')}
                                    </Button>
                                ) : (
                                    <Button type="primary" htmlType="submit" className="ant-btn-yellow">
                                        {getTranslation(language, 'forgotten_password_submit_button_text', 'Submit')}
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    </Form>
                    <Row className="signup-link-row">
                        <Col xs={24}>
                            <Link to="/" className="text-small">  {getTranslation(language, 'forgotten_password_back_link_text', 'Back')} </Link>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default PasswordResetForm;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { Drawer, Button } from 'antd';
import { useSelector } from 'react-redux';
import moment from 'moment';
import SubscriptionButton from '../../SubscriptionButton';
import OneOffPurchaseButton from '../../OneOffPurchaseButton';

import { getTranslation, getSelectedTranslation } from '../../../core/utils/helpers';
import useLanguage from '../../../core/language/useLanguage';
import CountDownTimer from '../../CountDownTimer';

import defaultLogo from '../../../assets/images/logo.png';

import './rightMenu.scss';
import NewRightMenu from './newRightMenu';
import GemstonesMenu from './gemstonesMenu';
import GeneratedMoonsign from '../../GeneratedMoonsign';

const RightMenu = (props) => {

    const [menuOpen, setMenuOpen] = useState(false);
    const { scrollTo } = props;

    const horoscope = useSelector(state => state.horoscope);
    const user = useSelector(state => state.user);

    const { language } = useLanguage();
    const { singleHoroscope, selectedHoroscope } = horoscope;
    const { userData } = user;

    const location = useLocation();
    const currentPath = location.pathname;

    if (currentPath === '/gemstones') {
        return <GemstonesMenu {...props} />;
    }

    if (currentPath === '/life-events' || currentPath === '/life-event-view') {
        return <NewRightMenu {...props} />;
    }

    let timeLeft = singleHoroscope?.free_trial_days_left ? singleHoroscope?.free_trial_days_left : 0;
    let goldTimeLeft = singleHoroscope?.free_trial_gold_days_left ? singleHoroscope?.free_trial_gold_days_left : 0;
    const freeTrialCoupon = singleHoroscope?.free_trial_coupon_days_left ? singleHoroscope?.free_trial_coupon_days_left : 0;

    if (timeLeft > 0) {
        const unixCreateTime = moment(userData?.created_at).add(91, 'day').unix();
        const unixTodaysDate = moment().unix();

        const unixTimeLeft = unixCreateTime - unixTodaysDate;

        if (unixTimeLeft > 0) {
            timeLeft = parseInt(unixTimeLeft, 10);
        } else {
            timeLeft = 0;
        }

        if (singleHoroscope?.is_user_subscribed === true) {
            timeLeft = 0;
        }
    }

    if (goldTimeLeft > 0) {
        const unixCreateTime = moment(userData?.created_at).add(91, 'day').unix();
        const unixTodaysDate = moment().unix();

        const unixTimeLeft = unixCreateTime - unixTodaysDate;

        if (unixTimeLeft > 0) {
            goldTimeLeft = parseInt(unixTimeLeft, 10);
        } else {
            goldTimeLeft = 0;
        }

        if (singleHoroscope?.is_user_subscribed === true) {
            goldTimeLeft = 0;
        }
    }

    const getHoroscopeDateRange = () => {
        if (selectedHoroscope?.type === '1') {
            const startDate = moment(selectedHoroscope?.date);
            const endDate = startDate.clone().add(6, 'days');
            return selectedHoroscope?.date ? `${startDate.format('MMMM D')} - ${endDate.format('MMMM D')}` : '';
        }
        if (selectedHoroscope?.type === '2') {
            return selectedHoroscope?.date ? moment(selectedHoroscope?.date).format('MMMM') : '';
        }
        if (selectedHoroscope?.type === '3') {
            return selectedHoroscope?.date ? moment(selectedHoroscope?.date).format('YYYY') : '';
        }
        return '';
    };

    const getHoroscopePurchaseButtonTitle = () => {
        if (!selectedHoroscope?.date) {
            return '';
        }
        if (selectedHoroscope?.type === '1') {
            return `${moment(selectedHoroscope.date).format('MMMM DD')} - ${moment(selectedHoroscope.date).add(6, 'day').format('MMMM DD')}`;
        }
        if (selectedHoroscope?.type === '2') {
            return `${moment(selectedHoroscope.date).format('MMMM YYYY')}`;
        }
        return '';
    };

    const horoscopeTranslation = getSelectedTranslation(singleHoroscope?.translations, language?.selectedLanguage?.id);

    const openDrawer = () => {
        setMenuOpen(true);
    };

    const closeDrawer = () => {
        setMenuOpen(false);
    };

    const renderHoroscopePart = (partData, partIndex) => {
        const horoscopePartLanguage = getSelectedTranslation(partData?.translations, language?.selectedLanguage?.id);

        return (
            <li key={`side-menu-part-item-${partData.id}`}>
                <button type="button" onClick={() => { scrollTo(partIndex); closeDrawer(); }}>
                    {horoscopePartLanguage?.name}
                </button>
            </li>
        );
    };

    const renderTimer = () => {
        if (goldTimeLeft > 0 && timeLeft === 0) {
            return (
                <CountDownTimer timerLeft={goldTimeLeft} />
            );
        }

        if (freeTrialCoupon > 0) {
            return (
                <CountDownTimer timerLeft={freeTrialCoupon} />
            );
        }

        return (
            <CountDownTimer timerLeft={timeLeft} />
        );

    };

    const displayPaymentButton = (selectedHoroscope?.parts?.length === 0);

    return (
        <div className="menu">
            <Button className="right-menu-toggle-button" onClick={openDrawer}>
                <div className="right-menu-toggle-icon" />
            </Button>
            <Drawer
                visible={menuOpen}
                onClose={closeDrawer}
                placement="right"
                width="350"
                className="right-menu-drawer"
                closable={false}>
                <div className="menu__header">
                    <Button className="right-menu-toggle-button" onClick={closeDrawer}>
                        <div className="right-menu-toggle-icon" />
                    </Button>
                </div>
                <div className="moonsign-block">
                    <GeneratedMoonsign />
                </div>
                <div className="logo-block">
                    {renderTimer()}
                    <img src={singleHoroscope?.mediaUrl} alt="horoscope" />
                    <h4 className="logo-title">{horoscopeTranslation?.name}</h4>
                    <p>{getHoroscopeDateRange()}</p>
                </div>
                {selectedHoroscope?.parts?.length > 0 ? (
                    <ul className="capter-menu">
                        {selectedHoroscope?.parts?.map((partItem, index) => (
                            renderHoroscopePart(partItem, index)
                        ))}
                    </ul>
                )
                    : null }

                <div className="menu__footer">
                    {selectedHoroscope?.parts?.length === 0
                        ? (
                            <OneOffPurchaseButton
                                btnClass="invert"
                                title={getHoroscopePurchaseButtonTitle()}
                                horoscopeDataId={selectedHoroscope?.id}
                                horoscopeType={selectedHoroscope?.type} />
                        )
                        : null }

                    {displayPaymentButton === true
                        ? (
                            <div style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                <SubscriptionButton btnClass="invert" horoscopeContentFrequency={selectedHoroscope?.type} />
                            </div>
                        )
                        : null }

                    <div className="bottom-sidebar-link">
                        <p>
                            <Link to="/my-subscriptions">
                                {`${getTranslation(language, 'subscription_settings_screen_title', 'Subscriptions')}`}
                            </Link>
                        </p>
                        <p>
                            <a href="https://www.prakashastrologer.com/disclaimer/" target="_blank" rel="noreferrer">
                                {`${getTranslation(language, 'settings_screen_astrologer_disclaimer_link_title', 'Astrologer Disclaimer')}`}

                            </a>
                        </p>
                    </div>
                </div>
            </Drawer>
        </div>
    );
};

RightMenu.propTypes = {
    items: PropTypes.array,
    logo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    placement: PropTypes.string,
    user: PropTypes.object,
    width: PropTypes.number,
    scrollTo: () => {},
};

RightMenu.defaultProps = {
    items: [],
    logo: defaultLogo,
    placement: 'right',
    width: 256,
    user: {},
    scrollTo: PropTypes.func,
};

export default RightMenu;

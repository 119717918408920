import React, { useEffect, useState } from 'react';
import { Button, notification } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { PropTypes } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'underscore';
import Main from '../../components/layout/Main';
import AddPaymentCardForm from './forms/AddPaymentCardForm';
import useLanguage from '../../core/language/useLanguage';
import { getTranslation } from '../../core/utils/helpers';
import { getPaymentCardsRequest, buySubscriptionRequest, buyConsultationRequest, buyOneOffRequest, buyLifeEventRequest } from '../../core/payment/paymentActions';
import './enterPaymentDetails.scss';
import YellowThemeButton from '../../components/YellowThemeButton';
import plusIcon from '../../assets/images/plus-icon.svg';

const EnterPaymentDetails = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.userData);
    const location = useLocation();
    const payment = useSelector(state => state.payment);
    const horoscope = useSelector(state => state.horoscope);
    const [addPaymentCard, setAddPaymentCard] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);

    useEffect(() => {
        dispatch(getPaymentCardsRequest());
        if (isEmpty(payment.paymentCards)) {
            setAddPaymentCard(true);
        }
    }, []);

    const toolbar = [{ label: 'Item 1', link: '/abc', iconName: 'home' }];
    const menu = [{ label: 'Item 1', link: '/abc', iconName: 'home', style: { fontSize: 24, color: 'red' } }];

    const { language } = useLanguage();

    const renderPaymentCard = (paymentCard) => {
        let active = false;

        if (paymentCard.stripe_card_id === selectedCard) {
            active = true;
        }
        return (
            <div className={`payment-card-block ${active === true ? 'active' : ''}`} key={`payment-card-${paymentCard.stripe_card_id}`} onClick={() => setSelectedCard(paymentCard.stripe_card_id)}>
                <p className="card-number">**** **** **** {paymentCard.last_4}, {paymentCard.brand}</p>
                <p className="brand">{paymentCard.name}, {paymentCard.exp_month}/{paymentCard.exp_year}</p>
            </div>
        );
    };

    const makePayment = () => {

        let error = false;

        if (selectedCard === null) {
            error = true;
            const infoTitle = language?.selectedLanguage?.id === 2 ? 'जानकारी' : 'Info';
            const infoDescription = language?.selectedLanguage?.id === 2 ? 'कृपया एक कार्ड चुनें' : 'Please select a card';
            notification.info({ message: infoTitle, description: infoDescription });
        }

        if (!error) {
            if (location?.state?.consultation_id) {
                dispatch(buyConsultationRequest({
                    user_id: user.id,
                    card_id: selectedCard,
                    consultation_id: location?.state?.consultation_id,
                    phone_number: location?.state?.phone_number,
                    questions: location?.state?.questions,
                    extra_questions: location?.state?.extra_questions,
                }));
            } else if (location?.state?.type === 'oneOffPurchase' && location?.state?.paymentPlanId) {
                dispatch(buyOneOffRequest({
                    payment_plan_id: location?.state?.paymentPlanId,
                    card_id: selectedCard,
                    horoscope_data_id: payment.selectedOneOffPaymentPlan?.horoscopeDataId,
                    selectedLang: language?.selectedLanguage?.id,
                }));
            } else if (location.state.type === 'lifeEvent') {
                const { lifeEvents } = payment;
                dispatch(buyLifeEventRequest({
                    horoscopeDataParts: lifeEvents,
                    selectedLang: language?.selectedLanguage?.id,
                    card_id: selectedCard,
                }));
            } else {
                dispatch(buySubscriptionRequest({
                    payment_plan_id: payment?.selectedPlan?.id,
                    card_id: selectedCard,
                    horoscope_id: horoscope?.singleHoroscope?.id,
                    selectedLang: language?.selectedLanguage?.id,
                }));
            }
        }
    };

    const renderBackButton = () => {
        let loc = '/dashboard';
        if (location.state.type === 'horoscope') {
            loc = '/order-confirm';
        } else if (location.state.type === 'oneOffPurchase') {
            loc = '/one-off-confirmation';
        } else if (location.state.type === 'oneOffPurchaseMobile') {
            return null;
        } else if (location.state.type === 'consultation') {
            loc = '/consultation-confirmation';
        } else if (location.state.type === 'lifeEvent') {
            loc = '/life-events';
        }
        return (
            <Link to={loc} className="text-small back-link">
                {getTranslation(language, 'webapp_back_link_title', 'Back')}
            </Link>
        );

    };

    return (
        <Main
            className="center-align"
            title={<div>Welcome</div>}
            headerTitle={<div>Horoscope Generated</div>}
            menu={menu}
            menuWidth={350}
            menuPlacement="right"
            toolbar={toolbar}
            footerContent={<div style={{ lineHeight: '30px', fontSize: '16px', float: 'right' }}>&copy; copyright 2019</div>}
            showHeader={false}
            showFooter={false}
            backLink={(
                <Button onClick={() => console.log('Back')}>
                    <LeftOutlined /> BACK
                </Button>
            )}>
            <div>
                <h1 className="title-text">{getTranslation(language, 'webapp_enter_card_details_title', 'Enter Card Details')}</h1>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {addPaymentCard === true
                        ? (
                            <div>
                                <AddPaymentCardForm
                                    extra_questions={location?.state?.extra_questions}
                                    questions={location?.state?.questions}
                                    type={location?.state?.type}
                                    consultation_id={location?.state?.consultation_id}
                                    phone_number={location?.state?.phone_number} />
                                <Button className="text-small ant-btn-link" onClick={() => setAddPaymentCard(false)}>{getTranslation(language, 'webapp_back_link_title', 'Back')}</Button>
                            </div>
                        )
                        : (
                            <div>
                                <YellowThemeButton style={{ width: '380px', maxWidth: '100%', marginBottom: '20px' }} onClick={() => setAddPaymentCard(true)}>
                                    <div className="button-content-container">
                                        <img className="add-plus-icon" src={plusIcon} alt="" />
                                        {getTranslation(language, 'payment_card_add_new_card_button_label', 'Add New Card')}
                                    </div>
                                </YellowThemeButton>
                                <div className="user-payment-cards">
                                    {
                                        payment.paymentCards.map(paymentCard => (
                                            renderPaymentCard(paymentCard)
                                        ))
                                    }
                                </div>
                                {payment.isFetching ? (
                                    <Button type="primary" className="ant-btn-yellow" loading>
                                        {getTranslation(language, 'payment_card_form_pay_button_label', 'Pay')}
                                    </Button>
                                ) : (
                                    <Button type="primary" className="ant-btn-yellow" onClick={() => makePayment()}>
                                        {getTranslation(language, 'payment_card_form_pay_button_label', 'Pay')}
                                    </Button>
                                )}
                                <br />
                                {renderBackButton()}
                            </div>
                        )}
                </div>
            </div>
        </Main>
    );
};

EnterPaymentDetails.propTypes = {
    type: PropTypes.string,
};

EnterPaymentDetails.defaultProps = {
    type: '',
};

export default EnterPaymentDetails;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Drawer, Button, Col, Row, Spin } from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import { isUndefined } from 'underscore';
import LanguageSelect from '../../LanguageSelect';

import useUser from '../../../core/user/useUser';
import defaultLogo from '../../../assets/images/logo.png';
import rightAngleIcon from '../../../assets/images/right-angle.svg';
import useLanguage from '../../../core/language/useLanguage';
import { getTranslation } from '../../../core/utils/helpers';
import { setVisitFromApp } from '../../../core/gemstones/gemstonesActions';
import './menu.scss';

const Menu = () => {

    const { logoutRequest, user } = useUser();
    const [menuOpen, setMenuOpen] = useState(false);
    const { language } = useLanguage();
    const dispatch = useDispatch();

    const horoscope = useSelector(state => state.horoscope);
    const endOfUrl = window.location.pathname.split('/').pop();
    const openDrawer = () => {
        setMenuOpen(true);
    };

    const closeDrawer = () => {
        setMenuOpen(false);
    };

    const handleGemstonesClick = () => {
        dispatch(setVisitFromApp(true));
    };

    const renderStreamingButton = () => {

        // only display streaming button on dashboard page
        if (endOfUrl === 'dashboard') {
            if (horoscope?.horocopeStreamingLiveData?.isLive === 0) {
                return (
                    <div className="rounded-button">
                        <div className="parent-body">
                            <div className="circle-wrapper">
                                <div className="inner-circle" />
                            </div>
                            <div className="rounded-button-inner-text">{`${getTranslation(language, 'live_text', 'Live')}`}</div>
                        </div>
                    </div>
                );
            }
            return (
                <a className="ahref-style" target="_blank" href={horoscope.horocopeStreamingLiveData.url} rel="noreferrer">
                    <div className="rounded-button rounded-button-active">
                        <div className="parent-body">
                            <div className="circle-wrapper">
                                <div className="inner-circle inner-circle-active" />
                            </div>
                            <div className="rounded-button-inner-text rounded-button-inner-text-active">{`${getTranslation(language, 'live_text', 'Live')}`}</div>
                        </div>
                    </div>
                </a>
            );
        }
        return null;
    };

    const renderMenuItems = () => {

        if (isUndefined(user.userData.id)) {
            return (
                <ul className="menu__nav">

                    <li>
                        <Link to={{ pathname: 'http://play.google.com/store/apps/details?id=com.prakash.astrology' }} target="_blank" className="ant-btn sidebar-btn" onClick={() => closeDrawer()}>
                            <span>{`${getTranslation(language, 'download_settings_screen_title', 'Get Android App')}`}</span>
                            <img src={rightAngleIcon} alt="Right angle" />
                        </Link>
                    </li>
                </ul>
            );

        }

        return (
            <ul className="menu__nav">
                <li>
                    <Link to="/dashboard" className="ant-btn sidebar-btn" onClick={() => closeDrawer()}>
                        <span>{`${getTranslation(language, 'dashboard_header_title', 'Dashboard')}`}</span>
                        <img src={rightAngleIcon} alt="Right angle" />
                    </Link>
                </li>
                <li>
                    <Link to="/my-subscriptions" className="ant-btn sidebar-btn">
                        <span>{`${getTranslation(language, 'subscription_settings_screen_title', 'My subscriptions')}`}</span>
                        <img src={rightAngleIcon} alt="Right angle" />
                    </Link>
                </li>
                <li>
                    <Link to="/consultations" className="ant-btn sidebar-btn">
                        <span>{`${getTranslation(language, 'settings_screen_consultations_link_title', 'Consultations')}`}</span>
                        <img src={rightAngleIcon} alt="Right angle" />
                    </Link>
                </li>
                <li>
                    <Link to="/gemstones" className="ant-btn sidebar-btn" onClick={handleGemstonesClick}>
                        <span>{`${getTranslation(language, 'settings_screen_gemstones_link_title', 'Gemstones')}`}</span>
                        <img src={rightAngleIcon} alt="Right angle" />
                    </Link>
                </li>
                <li>
                    <Link to="/moonsign-lookup" className="ant-btn sidebar-btn" onClick={() => closeDrawer()}>
                        <span>{`${getTranslation(language, 'moonsign_lookup', 'Moonsign Lookup')}`}</span>
                        <img src={rightAngleIcon} alt="Right angle" />
                    </Link>
                </li>
                <li>
                    <Link to="/rising-sign-lookup" className="ant-btn sidebar-btn" onClick={() => closeDrawer()}>
                        <span>{`${getTranslation(language, 'rising_sign_lookup', 'Rising Sign Lookup')}`}</span>
                        <img src={rightAngleIcon} alt="Right angle" />
                    </Link>
                </li>
                <li>
                    <Link to={{ pathname: 'http://play.google.com/store/apps/details?id=com.prakash.astrology' }} target="_blank" className="ant-btn sidebar-btn" onClick={() => closeDrawer()}>
                        <span>{`${getTranslation(language, 'download_settings_screen_title', 'Get Android App')}`}</span>
                        <img src={rightAngleIcon} alt="Right angle" />
                    </Link>
                </li>
                <li>
                    <Link to="/profile" className="ant-btn sidebar-btn" onClick={() => closeDrawer()}>
                        <span>{`${getTranslation(language, 'profile_settings_screen_title', 'Profile')}`}</span>
                        <img src={rightAngleIcon} alt="Right angle" />
                    </Link>
                </li>
            </ul>
        );
    };

    return (
        <div className="menu">
            <Button className="left-menu-toggle-button" onClick={openDrawer}>
                <div className="left-menu-toggle-icon" />
            </Button>
            <div className="streaming-button">
                {horoscope?.isLiveChecking
                    ? <Spin size="small" />
                    : renderStreamingButton() }
            </div>
            <Drawer
                visible={menuOpen}
                onClose={closeDrawer}
                placement="left"
                width="350"
                className="left-menu-drawer"
                closable={false}>
                <div className="menu__header">
                    <Row>
                        <Col span={12}>
                            <Button className="left-menu-toggle-button" onClick={closeDrawer}>
                                <div className="left-menu-toggle-icon" />
                            </Button>
                        </Col>
                        <Col span={12}>
                            <LanguageSelect />
                        </Col>
                    </Row>
                </div>
                <p className="side-menu-logo-title"> {`${getTranslation(language, 'menu_app_title', 'Prakash Astrologer')}`}</p>
                <div className="menu__content">
                    {renderMenuItems()}
                </div>
                <div className="menu__footer">
                    <button type="button"
                        onClick={() => (endOfUrl === 'consultations' ? logoutRequest({ path: 'consultations' })
                            : logoutRequest()
                        )}
                        className="ant-btn ant-btn-grey">
                        <span>{`${getTranslation(language,
                            !isUndefined(user.userData.id) ? 'settings_screen_logout_button_title' : 'login_screen_main_title', 'Logout')}`}
                        </span>
                    </button>
                    <div className="bottom-sidebar-link">
                        <a href="https://www.prakashastrologer.com/disclaimer/" target="_blank" rel="noreferrer">
                            {`${getTranslation(language, 'settings_screen_astrologer_disclaimer_link_title', 'Astrologer Disclaimer')}`}
                        </a>
                    </div>
                </div>
            </Drawer>
        </div>
    );
};

Menu.propTypes = {
    items: PropTypes.array,
    logo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    placement: PropTypes.string,
    user: PropTypes.object,
    width: PropTypes.number,
};

Menu.defaultProps = {
    items: [],
    logo: defaultLogo,
    placement: 'left',
    width: 256,
    user: {},
};

export default Menu;
